import { ChargeConstantCollection } from "constants/charge/ChargeConstantCollection";
import { EMPTY_SEARCH_MODE_ENTITY } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import _ from "lodash";
import { ManualChargeConstant } from "presentation/constant/ManualCharge/ManualChargeConstant";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useManualChargeHeaderMaintenanceVM } from "presentation/hook/ManualCharge/useManualChargeHeaderMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useManualChargeHeaderMaintenanceTracked } from "presentation/store/ManualCharge/ManualChargeHeaderMaintenanceProvider";
import { ConfirmDeleteModal } from "presentation/view/components/ConfirmDeleteModal";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const ManualChargeHeaderTitleBar:React.FC = () => {
    const [ manualChargeHeaderState ] = useManualChargeHeaderMaintenanceTracked();
    const manualChargeHeaderVM = useManualChargeHeaderMaintenanceVM();
    const HDR_STATE_CONST = ChargeConstantCollection.hdrState;

    const messageBarVM = useMessageBarVM();
    
    const [isLoading, setIsLoading] = useState(false);

    
    const currentRows = manualChargeHeaderState.selectedManualChargeHeaderRows;

    const [anainfoState] = useANAInfoTracked();
    const {allowCreate,allowUpdate,allowDelete} = anainfoState;
    const [ isShowDelete, setIsShowDelete ] = useState<boolean>(false);

    const handleSearchClick = useCallback(() => {
        manualChargeHeaderVM.onSearchClick();
    }, [manualChargeHeaderVM]);


    const handleAdd = useCallback(() => {
        manualChargeHeaderVM.onAdd();
    }, [manualChargeHeaderVM]);    

    // const handleDetail = useCallback(() => {
    //     if(selectedRowsCount === 1){
    //         manualChargeHeaderVM.onSelectedChargeHeader(currentRows[0])
    //         manualChargeHeaderVM.onDetail(currentRows[0]);
    //     }else{
    //         messageBarVM.showWarining('Please select a record!');  
    //     }        

    // }, [manualChargeHeaderVM, messageBarVM, currentRows, selectedRowsCount]);   
     

    const handleAccTml = useCallback(() => {

        if(_.isEmpty(currentRows)){
            messageBarVM.showWarining('Please select a record.')
        }else{
            if (currentRows.some(row => row.hdrState !== HDR_STATE_CONST.NIL)) {  
                messageBarVM.showWarining('The selected manual charge is not in "NIL" state.')
            }else{
                setIsLoading(true);
                manualChargeHeaderVM.onAccTml(currentRows).then(()=>{
                    manualChargeHeaderVM.onSearch(manualChargeHeaderState.searchCriteria,EMPTY_SEARCH_MODE_ENTITY).then(()=>{
                        setIsLoading(false);
                    }).catch((error) => {                    
                        setIsLoading(false)
                    })
                }).catch((error) => {                    
                    setIsLoading(false)
                    messageBarVM.showError(error.message)
                })
            }
        }

       
    }, [HDR_STATE_CONST.NIL, currentRows, manualChargeHeaderState.searchCriteria, manualChargeHeaderVM, messageBarVM]);

    const handleResumeAccTml = useCallback(() => {
        if(_.isEmpty(currentRows)){
            messageBarVM.showWarining('Please select a record.')
        }else{
            if (currentRows.some(row => row.hdrState !== HDR_STATE_CONST.TML)) { 
                messageBarVM.showWarining('The selected manual charge is not in "TML" state.'); 
            }else{
                setIsLoading(true);
                manualChargeHeaderVM.onResumeAccTml(currentRows).then(()=>{
                    manualChargeHeaderVM.onSearch(manualChargeHeaderState.searchCriteria,EMPTY_SEARCH_MODE_ENTITY).then(()=>{
                        setIsLoading(false)
                    }).catch((error) => {                    
                        setIsLoading(false)
                    })
                }).catch((error) => {                    
                    setIsLoading(false)
                    messageBarVM.showError(error.message)
                })
            }
        }
    }, [HDR_STATE_CONST.TML, currentRows, manualChargeHeaderState.searchCriteria, manualChargeHeaderVM, messageBarVM]);

    
    const handleConfirm = useCallback(() => {
        if(_.isEmpty(currentRows)){
            messageBarVM.showWarining('Please select a record.')
        }else{
            if (currentRows.some(row => row.hdrState !== HDR_STATE_CONST.NIL)) {  
                messageBarVM.showWarining('The selected manual charge is not in "NIL" state.')
            }else{
                setIsLoading(true);
                manualChargeHeaderVM.onConfirm(currentRows).then((res)=>{
                    if(res && !res.success){
                        messageBarVM.showError(res.data as string)
                    }
                    manualChargeHeaderVM.onSearch(manualChargeHeaderState.searchCriteria,EMPTY_SEARCH_MODE_ENTITY).then(()=>{
                        setIsLoading(false)
                    }).catch((error) => {                    
                        setIsLoading(false)
                    })
                }).catch((error) => {                    
                    setIsLoading(false)

                });
            }
        }        
    }, [HDR_STATE_CONST.NIL, currentRows, manualChargeHeaderState.searchCriteria, manualChargeHeaderVM, messageBarVM]);

    const handleUnConfirm = useCallback(() => {
        if(_.isEmpty(currentRows)){
            messageBarVM.showWarining('Please select a record.')
        }else{
            if (currentRows.some(row => row.hdrState !== HDR_STATE_CONST.CFM)) {  
                messageBarVM.showWarining('The selected manual charge is not in "CFM" state.')
            }else{
                setIsLoading(true);
                manualChargeHeaderVM.onUnConfirm(currentRows).then(()=>{
                    manualChargeHeaderVM.onSearch(manualChargeHeaderState.searchCriteria,EMPTY_SEARCH_MODE_ENTITY).then(()=>{
                        setIsLoading(false)
                    }).catch((error) => {                    
                        setIsLoading(false)
                    })
                }).catch((error) => {                    
                    setIsLoading(false)
                    messageBarVM.showError(error.message)
                });
            }
        }   
    }, [HDR_STATE_CONST.CFM, currentRows, manualChargeHeaderState.searchCriteria, manualChargeHeaderVM, messageBarVM]);

    
    const handleDelete = () => {
        if(_.isEmpty(currentRows)){
            messageBarVM.showWarining('Please select a record.')
        }else{
            if (currentRows.some(row => row.hdrState !== HDR_STATE_CONST.NIL)) {  
                messageBarVM.showWarining('The selected manual charge is not in "NIL" state.')
            }else{
                setIsShowDelete(false);
                setIsLoading(true);
                manualChargeHeaderVM.onDelete(currentRows).then((data)=>{
                    manualChargeHeaderVM.onSearch(manualChargeHeaderState.searchCriteria,EMPTY_SEARCH_MODE_ENTITY).then(()=>{
                        setIsLoading(false)
                    }).catch((error) => {                    
                        setIsLoading(false)
                    }).finally(() => {
                        if (data && data["deleteManualChargeFail"]) {
                            messageBarVM.showError(data["deleteManualChargeFail"]?.toString());
                        } else {
                            messageBarVM.showSuccess(MessageConstant.common.OPERATION_DONE_SUCCESSFUL.replace(
                                "{operationDone}", WorkspaceConstant.Common.BUTTON_DELETE));
                        }
                        setIsLoading(false);
                    })
                    
                }).catch((error) => {                    
                    setIsLoading(false)
                    messageBarVM.showError(error.message)
                });
            }
        }     
    }

    const handleDeleteClick = useCallback(() => {
        setIsShowDelete(true);
    }, []);    

    const handleCancel = () => {
        setIsShowDelete(false);
    }

    const handleReprintClick = () => {
        messageBarVM.showWarining('We are building this function!');
    }

    const isDisable = () => {
        if (currentRows && currentRows.length > 0) {
            return false;
        }
        return true;
    }
    

    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        <HeaderTitle>{ManualChargeConstant.Header.MANUAL_CHARGE}</HeaderTitle>
        {/* <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[{ title: CHARGEDETAIL_CONSTANT.CHARGE_TITLE }, { title: CHARGEDETAIL_CONSTANT.SYSTEMCHARGE_TITLE }, { title: ManualChargeConstant.Header.MANUAL_CHARGE }]}></HPHBreadcrumb>
        </Breadcrumb> */}
        <StyledAction className="tm-animated-wrapper">
            <IconButton fileName='Icon-search' disabled={false} size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearchClick} />
            {(allowCreate) && <IconButton fileName='Icon-add' disabled={false} size='medium' toolTipText={'Add'} toolTipArrow={false} onClick={handleAdd} />}
            {(allowDelete || allowUpdate) && <div className="add-seperator"/>}
            {(allowUpdate) && <><HPHButton label={WorkspaceConstant.Common.BUTTON_ACCTML} disabled={isDisable()} size={'Small'} theme={'Secondary'} onClick={handleAccTml} />            
            <HPHButton label={WorkspaceConstant.Common.BUTTON_RESUMEACCTML} size={'Small'} disabled={isDisable()} theme={'Secondary'} onClick={handleResumeAccTml} />     
            <div className="add-seperator"/></>}      
            {(allowDelete) && <HPHButton label={WorkspaceConstant.Common.BUTTON_DELETE} disabled={isDisable()} size={'Small'} theme={'Secondary'} onClick={handleDeleteClick} />}
            {(allowUpdate) && <><HPHButton label={WorkspaceConstant.Common.BUTTON_REPRINT} disabled={isDisable()} size={'Small'} theme={'Secondary'} onClick={handleReprintClick} />
            <div className="add-seperator"/>  
            <HPHButton label={WorkspaceConstant.Common.BUTTON_UNCONFIRM} size={'Small'} disabled={isDisable()} theme={'Secondary'} onClick={handleUnConfirm} /> 
            <HPHButton label={WorkspaceConstant.Common.BUTTON_CONFIRM} size={'Small'} disabled={isDisable()} theme={'Primary'} onClick={handleConfirm} /></>}        
             {/*<IconButton fileName='Icon-copy' disabled={false} size='medium' toolTipText={'Detail'} toolTipArrow={false} onClick={handleDetail} />
            <div className="add-seperator"/>
            <IconButton fileName='Icon-alert-circle' disabled={false} size='medium' toolTipText={'Acc Tml'} toolTipArrow={false} onClick={handleAccTml} />
            <IconButton fileName='Icon-reset' disabled={false} size='medium' toolTipText={'Resume Acc Tml'} toolTipArrow={false} onClick={handleResumeAccTml} /> 
            <div className="add-seperator"/>
            <IconButton fileName='Icon-tick' disabled={false} size='medium' toolTipText={'Confirm'} toolTipArrow={false} onClick={handleConfirm} />
            <IconButton fileName='Icon-recover' disabled={false} size='medium' toolTipText={'UnConfirm'} toolTipArrow={false} onClick={handleUnConfirm} />*/}
            
            {isShowDelete && <ConfirmDeleteModal headerText={`${WorkspaceConstant.Common.BUTTON_DELETE} ${ManualChargeConstant.Header.MANUAL_CHARGE}`} 
                contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
                visible={isShowDelete}
                onCancelClick={handleCancel}
                onDeleteClick={handleDelete}
            />}
        </StyledAction>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
    </Sidebarheader>
}

export default memo(ManualChargeHeaderTitleBar);