import { EMPTY_SEARCH_MODE_ENTITY } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { ManualChargeHeaderSearchCriteria } from "presentation/constant/ManualCharge/ManualChargeHeaderSearchCriteria";
import { useManualChargeHeaderMaintenanceVM } from "presentation/hook/ManualCharge/useManualChargeHeaderMaintenanceVM";
import { useManualChargeHeaderMaintenanceTracked } from "presentation/store/ManualCharge/ManualChargeHeaderMaintenanceProvider";
import { useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import ManualChargeHeaderSearchForm from "./ManualChargeHeaderSearchForm";

const ManualChargeHeaderSearchPanel = () => {
    const manualChargeHeaderVM = useManualChargeHeaderMaintenanceVM();
    const [manualChargeHeaderState] = useManualChargeHeaderMaintenanceTracked();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const resetButtonClicked = useCallback(() => {
        manualChargeHeaderVM.onRemoveAllSearchCriteria();
    }, [manualChargeHeaderVM]);
    
    const searchButtonClicked = useCallback((criteria:ManualChargeHeaderSearchCriteria) => {
        setIsLoading(true);
        manualChargeHeaderVM.onSearch(criteria,EMPTY_SEARCH_MODE_ENTITY).then((data) => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })
        
    }, [manualChargeHeaderVM])

    return (
        <div className='side-form-content-left-wrapper' style={{padding:"20px 20px 20px 0px"}}>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{'Search Criteria'}</SidebarTitle>

                        <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={manualChargeHeaderVM.onSearchClick} />
                        </SidebarActionCross>
                    </Sidebarheader>                
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium"/>}

            <div className={'add-edit-form'} style={{ height:'75vh', maxHeight:'75vh' , overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        
                        {/* Stack Form Start */}
                        <ManualChargeHeaderSearchForm/>
                    </div>                  
                </div>
            </div>
            <SidebarActionBar>
                <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={resetButtonClicked} />
                <HPHButton label={'Search'} size={'Small'} theme={'Primary'} onClick={() => searchButtonClicked(manualChargeHeaderState.searchCriteria)} />
            </SidebarActionBar>
        </div>
    );
}

export default ManualChargeHeaderSearchPanel;
